import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LayoutSize } from '@entities/layout/layout-size.class';
import { ScreenSize } from '@entities/layout/screen-size.enum';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private _currentBreakpoint$: BehaviorSubject<LayoutSize> = new BehaviorSubject<LayoutSize>(null);
  public currentBreakpoint$ = this._currentBreakpoint$.asObservable();
  private breakpointMap = new Map([
    [Breakpoints.XSmall, ScreenSize.XSmall],
    [Breakpoints.Small, ScreenSize.Small],
    [Breakpoints.Medium, ScreenSize.Medium],
    [Breakpoints.Large, ScreenSize.Large],
    [Breakpoints.XLarge, ScreenSize.XLarge],
  ]);

  public constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .pipe(takeUntilDestroyed())
      .subscribe((result: BreakpointState) => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            const currentSize = this.breakpointMap.get(query) ?? ScreenSize.Unknown;
            this._currentBreakpoint$.next(new LayoutSize(currentSize));
          }
        }
      });
  }

  /**
   * @param minWidth String in pixels to watch for, ex: 140px
   */
  public getMinWidthObservable(minWidth: string): Observable<BreakpointState> {
    return this.breakpointObserver.observe(['(min-width: ' + minWidth + ')']);
  }

  /**
   * String in pixels to watch for, ex: 450px
   * @param maxWidth Testing
   */
  public getMaxWidthObservable(maxWidth: string): Observable<BreakpointState> {
    return this.breakpointObserver.observe(['(max-width: ' + maxWidth + ')']);
  }
}
