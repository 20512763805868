import { ScreenSize } from './screen-size.enum';

export class LayoutSize {
  public readonly isXSmall: boolean = false;
  public readonly isSmall: boolean = false;
  public readonly isMedium: boolean = false;
  public readonly isLarge: boolean = false;
  public readonly isXLarge: boolean = false;
  public readonly isUnknown: boolean = false;
  public constructor(currentScreenSize: ScreenSize = ScreenSize.Unknown) {
    this.isXSmall = currentScreenSize === ScreenSize.XSmall || currentScreenSize === ScreenSize.Small;
    this.isSmall = currentScreenSize === ScreenSize.Small || this.isXSmall;
    this.isMedium = currentScreenSize === ScreenSize.Medium || this.isSmall;
    this.isLarge = currentScreenSize === ScreenSize.Large || this.isMedium;
    this.isXLarge = currentScreenSize === ScreenSize.XLarge || this.isLarge;
    this.isUnknown = currentScreenSize === ScreenSize.Unknown;
  }
}
