import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { IconComponent } from '@core/components/icon/icon.component';

@Component({
  selector: 'pd-error-message',
  standalone: true,
  imports: [CommonModule, IconComponent],
  template: `<pd-icon class="text-3xl" icon="warning"></pd-icon>
    <h3>
      Something went wrong,
      <span class="cursor-pointer text-primary" (click)="reloadUrl()">please try again.</span>
    </h3>`,
})
export class ErrorMessageComponent {
  public constructor(private router: Router) {}

  public reloadUrl(): void {
    const currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => true;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    });
  }
}
