import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '@core/components/icon/icon.component';

@Component({
  selector: 'pd-empty-message',
  standalone: true,
  imports: [CommonModule, IconComponent],
  template: `<pd-icon class="text-3xl" icon="table-list"></pd-icon>
    <h3>{{ message }}</h3>`,
})
export class EmptyMessageComponent {
  @Input()
  public message = 'No results found. If applicable, change your search or filters.';
}
