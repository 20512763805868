import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '@core/components/buttons/button.component';
import { IconComponent } from '@core/components/icon/icon.component';
import { PageComponentsModule } from './page/page-components.module';
import { MoreMenuComponent } from './more-menu/more-menu.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { StateComponentsModule } from '@core/components/states/state-components.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ButtonComponent,
    IconComponent,
    StateComponentsModule,
    PageComponentsModule,
    MoreMenuComponent,
    MatMenuModule,
    MatSnackBarModule,
    MatDialogModule,
  ],
  exports: [
    CommonModule,
    ButtonComponent,
    IconComponent,
    StateComponentsModule,
    PageComponentsModule,
    MoreMenuComponent,
    MatMenuModule,
    MatSnackBarModule,
    MatDialogModule,
  ],
})
export class CommonComponentsModule {}
