import { NgModule } from '@angular/core';
import { EmptyMessageComponent } from '@core/components/states/empty-message/empty-message.component';
import { ErrorMessageComponent } from '@core/components/states/error-message/error-message.component';
import { LoaderComponent } from '@core/components/states/loader/loader.component';

@NgModule({
  declarations: [],
  imports: [ErrorMessageComponent, LoaderComponent, EmptyMessageComponent],
  exports: [ErrorMessageComponent, LoaderComponent, EmptyMessageComponent],
})
export class StateComponentsModule {}
